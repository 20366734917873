<template>
  <div>
    <div class="row page-title-header">
      <div class="col-12">
        <div class="page-header">
          <b-breadcrumb class="m-0">
            <b-breadcrumb-item
              :to="{
                name: 'DashboardHome',
              }"
            >
              <i class="fa fa-home"></i>
            </b-breadcrumb-item>
            <b-breadcrumb-item :to="{ name: 'CustomerLevelsList' }">
              會員升級條件
            </b-breadcrumb-item>
            <b-breadcrumb-item active>
                規則設定
            </b-breadcrumb-item>
          </b-breadcrumb>
        </div>
      </div>
    </div>

    <b-card>
    <div class="row d-flex mb-2 mb-xl-2 justify-content-between">
      <div class="col-12 col-xl-6 d-flex align-items-center">
        <h4 class="mb-2 mb-xl-0 mr-2 font-weight-bold">規則設定</h4>
      </div>
    </div>

      <b-overlay
        :show="isLoading"
        rounded
        opacity="0.6"
        spinner-small
        spinner-variant="primary"
      >
        <!-- 基本設定 -->
        <section class="row mb-5">
          <div class="col-12 col-xl-8">
            <h4 class="mb-4 text-primary">基本設定</h4>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="會員等級"
              label-for="name"
            >
              <b-form-select
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="customer_level_rule.customer_level_id"
                :options="customerLevelOptions"
                :state="v$.customer_level_rule.customer_level_id.$error ? false : null"
                :disabled="isShow"
              ></b-form-select>
              <b-form-invalid-feedback :state="!v$.customer_level_rule.customer_level_id.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="順序"
            >
              <b-form-input
                type="number"
                min="0"
                step="1"
                v-model="customer_level_rule.order"
                :disabled="isShow"
              ></b-form-input>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="類型"
              label-for="type"
            >
              <b-form-select
                id="type"
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="customer_level_rule.type"
                :options="customerLevelTypeOptions"
                :state="v$.customer_level_rule.type.$error ? false : null"
                :disabled="isShow"
              ></b-form-select>
            </b-form-group>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              content-cols="12"
              content-cols-lg="6"
              label="是否啟用"
            >
              <b-form-checkbox
                class="mb-2 mr-sm-2 mb-sm-0"
                v-model="customer_level_rule.is_enabled"
                :disabled="isShow">
              </b-form-checkbox>
            </b-form-group>
          </div>
        </section>
        <!-- 條件設定 -->
        <section class="row mb-5">
          <div class="col-12 col-xl-8">
            <h4 class="mb-4 text-primary">條件設定</h4>
            <small>當滿足以下條件，客戶將被自動增加/升級到此會員級別:</small>
            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="條件類型"
              label-for="role"
            >
              <b-form-select
                id="name"
                class="mb-2 mr-sm-2 mb-sm-0"
                :options="roleOptions"
                v-model="rule.rule_template_type"
                :state="v$.rule.rule_template_type.$error ? false : null"
                :disabled="isShow"
              ></b-form-select>
              <b-form-invalid-feedback :state="!v$.rule.rule_template_type.$error"
                >此欄位為必填</b-form-invalid-feedback
              >
            </b-form-group>
            <!-- type ==2 每年週期 -->
            <b-form-group
              v-if="rule.rule_template_type == 2"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="每年"
              label-for="year-period"
            >
              <div class="d-flex align-items-center">
                <b-form-select v-model="form.start_month" :options="monthOptions" class="mr-2" :disabled="isShow"></b-form-select>
                <span>月</span>
                <b-form-select v-model="form.start_day" :options="baseStartDayOptions" class="mx-2" :disabled="isShow"></b-form-select>
                <span>日</span>
                <span class="mx-2">~</span>
                <b-form-select v-model="form.end_month" :options="monthOptions" class="mr-2" :disabled="isShow"></b-form-select>
                <span>月</span>
                <b-form-select v-model="form.end_day" :options="baseEndDayOptions" class="mx-2" :disabled="isShow"></b-form-select>
                <span>日</span>
              </div>
            </b-form-group>
            <!-- type == 3 月週期 -->
            <b-form-group
              v-if="rule.rule_template_type == 3"
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label="指定期限"
              label-for="period"
            >
              <b-input-group>
              <b-form-input v-model="form.month_amount" type="number" min="0" :disabled="isShow"></b-form-input>
                <b-input-group-append>
                  <b-input-group-text>個月內</b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>

            <b-form-group
              label-cols="12"
              label-cols-lg="2"
              label-size="sm"
              label=""
              label-for="amount"
            >
              <b-form-radio-group
                v-model="rule.type"
              >
                <div class="d-flex mb-2">
                  <b-form-radio value="total" class="form-inline">
                  消費總額
                    <b-input-group prepend="NT$" class="ml-2">
                      <b-form-input
                        id="name"
                        class="mr-sm-2 mb-sm-0"
                        type="number"
                        min="0"
                        v-model="rule.required_amount"
                        :state="v$.rule.required_amount.$error ? false : null"
                        :disabled="isShow || rule.type != 'total'"
                      ></b-form-input>
                    </b-input-group>
                    </b-form-radio>
                </div>
                <div class="d-flex mb-2" v-if="rule.rule_template_type !== 1">
                  <b-form-radio value="count" class="form-inline">
                    滿
                    <b-form-input
                      id="name"
                      class="ml-2 mr-sm-2 mb-sm-0 w-10"
                      type="number"
                      min="1"
                      v-model="form.required_count"
                      :disabled="isShow || rule.type != 'count'"
                    ></b-form-input>
                    次，
                    每次金額
                    <b-input-group prepend="NT$" class="ml-2">
                      <b-form-input
                        id="name"
                        class="mr-sm-2 mb-sm-0"
                        type="number"
                        min="1"
                        v-model="form.amount"
                        :disabled="isShow || rule.type != 'count'"
                      ></b-form-input>
                    </b-input-group>
                    以上
                  </b-form-radio>
                </div>
                <div class="d-flex mb-2" v-if="rule.rule_template_type == 4">
                  <b-form-radio value="form_count" class="form-inline">
                    活動報到
                    <b-input-group class="ml-2">
                      <b-form-input
                        id="name"
                        class="mr-sm-2 mb-sm-0"
                        type="number"
                        min="0"
                        v-model="rule.required_amount"
                        :state="v$.rule.required_amount.$error ? false : null"
                        :disabled="isShow || rule.type != 'form_count'"
                      ></b-form-input>
                    </b-input-group>
                    次以上
                  </b-form-radio>
                </div>
              </b-form-radio-group>
            </b-form-group>
            <small>*注意 : 當顧客訂單完成付款後，系統將於隔日凌晨依照升級條件計算會員等級。</small>
          </div>
        </section>
      </b-overlay>

      <div class="d-flex justify-content-center">
        <b-button class="mr-4" variant="outline-danger" @click="handleCancel"
          >返回</b-button
        >
        <b-overlay
          :show="isLoading && !isShow"
          rounded
          opacity="0.6"
          spinner-small
          spinner-variant="primary"
          class="d-inline-block"
          v-if="!isShow"
        >
          <b-button variant="success" @click="handleSubmit">
            {{ isEditing ? "確認修改" : "確認新增" }}
          </b-button>
        </b-overlay>
      </div>
    </b-card>
  </div>
</template>

<script>
import useVuelidate from "@vuelidate/core";
import { required } from "@vuelidate/validators";
import { subDays } from "date-fns";
import PermissionChecker from "@/utils/PermissionChecker";
import {mapGetters, mapState} from "vuex";
import * as consts from "@/consts";
import customerLevelRuleApi from "@/apis/customer-level-rules";
import customerLevelApi from "@/apis/customer-levels";

export default {
  components: {
  },
  setup() {
    const v$ = useVuelidate();
    return { v$ };
  },
  data() {
    return {
      consts,
      disabledDates: { to: subDays(new Date(), 1) },
      isLoading: false,
      customerLevelOptions: [],
      customerLevelTypeOptions: [
        {
          value: "direct",
          text: '直升'
        },
        {
          value: "upgrade",
          text: '升等'
        },
        {
          value: "keep",
          text: '續等'
        }
      ],
      form: {
        month_amount: 0,
        amount: 0,
        start_month: 1,
        end_month: 1,
        start_day: 1,
        end_day: 1,
        required_count: 0,
      },
      customer_level_rule: {
        customer_level_id: null,
        type: "upgrade",
        order: 0,
        is_enabled: true,
      },
      criteria: {
          content: "total",
          attributes: [
          {
            condition: null,
            key: null,
            value: null,
          }
        ]
      },
      rule: {
          rule_template_type : 1,
          required_amount: 0,
          type: "total",
          criteria: [],
      },
      roleOptions: [
        { value: 1, text: "單次" },
        { value: 2, text: "固定週期" },
        { value: 3, text: "自訂期限" },
        { value: 4, text: "無期限" },
      ],
      monthOptions: Array.from({length: 12}, (_, i) => ({ value: i + 1, text: i + 1 })),
      baseStartDayOptions: Array.from({length: 31}, (_, i) => ({ value: i + 1, text: i + 1 })),
      baseEndDayOptions: Array.from({length: 31}, (_, i) => ({ value: i + 1, text: i + 1 })),
    };
  },
  watch: {
    "rule.start_month"(value) {
      this.updateDayOptions('start', value);
    },
    "rule.end_month"(value) {
      this.updateDayOptions('end', value);
    },
    "rule.rule_template_type"(value) {
      if (value == 1) {
        this.rule.required_amount = 0
        this.rule.type = "total"
      }
    }
  },
  validations() {
    return {
      rule: {
        rule_template_type: {},
        amount: {},
        required_amount: {},
        type: {}
      },
      form: {
        month_amount: {},
        amount: {},
        start_month: {},
        start_day: {},
        end_month: {},
        end_day: {},
      },
      customer_level_rule: {
        customer_level_id: { required },
        type: {},
        order: {},
        is_enabled: { required },
      },
    };
  },
  mounted() {
    this.getCustomerLevelsList();
    if (this.$route.query.level_id) {
      this.customer_level_rule.customer_level_id = this.$route.query.level_id;
    }
    if (this.isEditing || this.isShow) {
      this.getRule();
    }
  },
  computed: {
    ...mapState("general", {
      organization: (state) => state.organization,
    }),
    ...mapGetters("route", ["routeQuery"]),
    isShow() {
      return this.$route.name === "CustomerLevelRuleView";
    },
    isEditing() {
      return this.$route.name === "CustomerLevelRuleEdit";
    },
  },
  methods: {
    async getCustomerLevelsList() {
      try {
        this.isLoading = true;
        const { data } = await customerLevelApi.list({ all: true });
        this.customerLevelOptions = data.data.map((item) => ({
          value: item.id,
          text: item.name,
        }));
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    async getRule() {
      try {
        this.isLoading = true;
        const response = await customerLevelRuleApi.getRule(this.organization, this.$route.params.id);
        this.customer_level_rule = response.data.data;
        this.rule = this.customer_level_rule.rule;
        this.criteria = this.rule.criteria;
        this.form = this.formatCriteria();
      } catch (error) {
        console.error(error);
      } finally {
        this.isLoading = false;
      }
    },
    // 這是for 強制符合後端格式 前端寫死的模版
    // TODO: 之後要改成拼圖化設定 就不能用這個模式了
    formatCriteria() {
      const templateType = this.rule.rule_template_type ?? 1
      const attributes = this.criteria.attributes;
      const rule = this.rule
      let form = {
        month_amount: 0,
        required_count: 0,
        amount: 0,
        start_month: 1,
        start_day: 1,
        end_month: 12,
        end_day: 31,
      }
      if(templateType == 1) {
        if (rule.type == 'count') {
          const amountAttributes = attributes.find((item) => item.key == 'total');
          if (amountAttributes) {
            form.amount = amountAttributes.value;
          }
          form.required_count = rule.required_amount;
          this.rule.required_amount = 0;
        }
      } else if (templateType == 2) {
        const dateTimeAttributes = attributes.find((item) => item.key == 'order_at' && item.condition == 'between');
        if (dateTimeAttributes) {
          form.start_month = dateTimeAttributes.value[0].split('/')[0];
          form.start_day = dateTimeAttributes.value[0].split('/')[1];
          form.end_month = dateTimeAttributes.value[1].split('/')[0];
          form.end_day = dateTimeAttributes.value[1].split('/')[1];
        }
        if (rule.type == 'count') {
          const amountAttributes = attributes.find((item) => item.key == 'total');
          if (amountAttributes) {
            form.amount = amountAttributes.value;
          }
          form.required_count = rule.required_amount;
          this.rule.required_amount = 0;
        }
      } else if (templateType == 3) {
        const dateTimeAttributes = attributes.find((item) => item.key == 'order_at' && item.type == 'month');
        if (dateTimeAttributes) {
          form.month_amount = dateTimeAttributes.value;
        }
        if (rule.type == 'count') {
          const amountAttributes = attributes.find((item) => item.key == 'total');
          if (amountAttributes) {
            form.amount = amountAttributes.value;
          }
          form.required_count = rule.required_amount;
          this.rule.required_amount = 0;
        }
      } else if (templateType == 4) {
        if (rule.type == 'count') {
          const amountAttributes = attributes.find((item) => item.key == 'total');
          if (amountAttributes) {
            form.amount = amountAttributes.value;
          }
          form.required_count = rule.required_amount;
          this.rule.required_amount = 0;
        }
      }

      return form;
    },
    prepareCriteria() {
      let criteria = {
        attributes: [],
      };
      if (this.rule.type != 'form_count') {
        criteria.query_type = 'order'
        criteria.content =  "available_price"
      } else {
        criteria.query_type = 'form_result'
        criteria.content =  "id"
      }
      if (this.rule.rule_template_type == 1) {
        if (this.rule.type == 'count') {
          criteria.attributes.push({
            condition: "greater_than",
            key: "total",
            value: Number(this.form.amount),
          });
          this.rule.required_amount = Number(this.form.required_count);
        }
      } else if (this.rule.rule_template_type == 2) {
        criteria.attributes = [
          {
            condition: "between",
            type: "date_range",
            key: "order_at",
            value: [
              `${this.form.start_month}/${this.form.start_day}`,
              `${this.form.end_month}/${this.form.end_day}`,
            ],
          },
        ];
        if (this.rule.type == 'count') {
          criteria.attributes.push({
            condition: "greater_than",
            key: "total",
            value: Number(this.form.amount),
          });
          this.rule.required_amount = Number(this.form.required_count);
        }
      } else if (this.rule.rule_template_type == 3) {
        criteria.attributes = [
          {
            condition: "greater_than",
            type: "month",
            key: "order_at",
            value: Number(this.form.month_amount),
          },
        ];
        if (this.rule.type == 'count') {
          criteria.attributes.push({
            condition: "greater_than",
            key: "total",
            value: Number(this.form.amount),
          });
          this.rule.required_amount = Number(this.form.required_count);
        }
      } else if (this.rule.rule_template_type == 4) {
        if (this.rule.type == 'count') {
          criteria.attributes.push({
            condition: "greater_than",
            key: "total",
            value: Number(this.form.amount),
          });
          this.rule.required_amount = Number(this.form.required_count);
        }
        if (this.rule.type == 'form_count') {
          criteria.attributes.push({
            condition: "equal",
            key: "checkin_status",
            value: "attended",
          });
        }
      }
      return criteria;
    },
    async handleSubmit() {
      try {
        const result = await this.v$.$validate();
        if (!result) return;

        this.isLoading = true;

        delete this.customer_level_rule.rule;
        const data = {
          rule: {
            criteria: this.prepareCriteria(),
            required_amount: Number(this.rule.required_amount),
            rule_template_type: this.rule.rule_template_type,
            type: this.rule.type ?? 'total',
          },
          ...this.customer_level_rule
        };
        if (this.isEditing) {
          await customerLevelRuleApi.updateRule(this.organization, data);
        } else {
          await customerLevelRuleApi.createRule(this.organization, data);
        }
        this.$router.push({
          name: "CustomerLevelRuleList",
          query: this.routeQuery,
        });
      } catch (error) {
        console.error(error);
        this.$swal("錯誤", "", "error");
      } finally {
        this.isLoading = false;
      }
    },
    handleCancel() {
      this.$router.push({
        name: "CustomerLevelRuleList",
        query: this.routeQuery,
      });
    },
    updateDayOptions(type, month) {
      const daysInMonth = new Date(new Date().getFullYear(), month, 0).getDate();
      const newDayOptions = Array.from({length: daysInMonth}, (_, i) => ({ value: i + 1, text: i + 1 }));

      if (type === 'start') {
        this.baseStartDayOptions = newDayOptions;
        if (this.selectedStartDay > daysInMonth) {
          this.selectedStartDay = daysInMonth;
        }
      } else {
        this.baseEndDayOptions = newDayOptions;
        if (this.selectedEndDay > daysInMonth) {
          this.selectedEndDay = daysInMonth;
        }
      }
    },
    checkPermission(permissions) {
      const checker = new PermissionChecker();
      return checker.check(permissions);
    },
  },
};
</script>

<style scoped>
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
}
</style>
